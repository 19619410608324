<template>
  <div
    v-if="Object.keys(user).length"
    id="user-profile"
  >
    <profile-header :user="user" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about :user="user" />
          <profile-group :groups="groups" />
        </b-col>

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <profile-habit :performed-tasks="performedTasks" />
        </b-col>
        <!-- post -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        >
          <profile-game
            :games="games"
            :header-text="$t('MY_GAMES')"
          />
        </b-col>
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileGroup from './ProfileGroup.vue'
import ProfileHabit from './ProfileHabit.vue'
import ProfileGame from './ProfileGame.vue'
// eslint-disable-next-line import/extensions, import/no-unresolved

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileHeader,
    ProfileAbout,
    ProfileGroup,
    ProfileHabit,
    ProfileGame,
  },
  data() {
    return {
      games: [],
      user: { },
      performedTasks: [],
      groups: [],
    }
  },
  created() {
    let id = 0
    if (router.currentRoute.params.id) {
      id = router.currentRoute.params.id
    } else {
      id = useJwt.getUser().id
    }
    this.fetchUser(id)
    this.fetchUserPerformedTasks(id)
    this.fetchGroupGameByID(id)
  },
  methods: {
    ...mapActions('user', ['updateUser',
      'fetchUserByID',
      'fetchGroupGameByUserID',
      'fetchPerformedTasksByUserID']),
    async fetchUser(id) {
      try {
        await this.fetchUserByID(id).then(response => {
          if (response) {
            const { data } = response.data
            this.user = data
          }
        })
      } catch (error) {
        //this.showErrorMessage()
      }
    },
    async fetchUserPerformedTasks(id) {
      try {
        await this.fetchPerformedTasksByUserID(id).then(response => {
          if (response) {
            this.performedTasks = response
          }
        })
      } catch (error) {
        //this.showErrorMessage()
      }
    },
    async fetchGroupGameByID(id) {
      try {
        await this.fetchGroupGameByUserID(id).then(response => {
          if (response) {
            const results = response
            results.forEach(e => {
              this.groups.push(e.group)
              e.group.competition_groups.forEach(c => {
                if (c.competition) {
                  this.games.push(c.competition)
                }
              })
            })
          }
        })
      } catch (error) {
        //this.showErrorMessage()
      }
    },
  },
  setup() {
    const {
      showErrorMessage,
    } = mixinAlert()

    return {
      showErrorMessage,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
